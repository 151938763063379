import React, { lazy, Suspense } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useAccess } from './lib/useAccess';
import { apolloClient } from './lib/apollo/client';
import { HttpClientProvider } from './providers/HttpClientProvider';
import { ApolloProvider } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { UserConfigurationProvider } from './providers/UserConfigurationProvider';
const CustomsPage = lazy(() => import('./views/CustomsPage'));
const TerminalPage = lazy(() => import('./views/TerminalPage'));
const TrafficPage = lazy(() => import('./views/TrafficPage'));

export function AuthenticatedUser() {
  const { instance } = useMsal();

  const client = apolloClient(instance);

  return (
    <ApolloProvider client={client}>
      <UserConfigurationProvider client={client}>
        <AuthenticatedAndFetchedUser />
      </UserConfigurationProvider>
    </ApolloProvider>
  );
}

function AuthenticatedAndFetchedUser() {
  const { terminal, traffic, customsOfficer } = useAccess();
  const { t } = useTranslation();

  return (
    <HttpClientProvider>
      <Router>
        <Switch>
          <Route path="/terminal">
            <Suspense fallback="loading">
              <TerminalPage />
            </Suspense>
          </Route>

          <Route path="/traffic">
            {traffic ? (
              <Suspense fallback="loading">
                <TrafficPage />
              </Suspense>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route path="/customs">
            {customsOfficer ? (
              <Suspense fallback="loading">
                <CustomsPage />
              </Suspense>
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route path="/">
            <header
              className="App-header"
              style={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h1>{t('pages.switch.title')}</h1>
              <p>{t('pages.switch.ingress')}</p>

              {terminal && !traffic && <Redirect to="/terminal" />}
              <Box>
                {traffic && (
                  <Link to="/traffic">{t('pages.switch.links.traffic')}</Link>
                )}
              </Box>
              <Box>
                {terminal && (
                  <Link to="/terminal">{t('pages.switch.links.terminal')}</Link>
                )}
              </Box>
              <Box>
                {customsOfficer && (
                  <Link to="/customs">{t('pages.switch.links.customs')}</Link>
                )}
              </Box>
            </header>
          </Route>
        </Switch>
      </Router>
    </HttpClientProvider>
  );
}
